/*!
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: 10px
}

.slider[class*="span"] {
  float: none;
  margin-left: 0;
}

.slider-horizontal {
  width: 210px;
  height: 20px;
}

.slider-horizontal .slider-handle-container,
.slider-horizontal .slider-track {
  height: 10px;
  margin-top: -5px;
  top: 50%;
}

.slider-horizontal .slider-track {
  left: 0;
  right: 0;
}

.slider-horizontal .slider-handle-container {
  left: 10px;
  right: 10px;
}

.slider-horizontal .slider-selection {
  height: 100%;
  top: 0;
  bottom: 0;
}

.slider-horizontal .slider-handle {
  margin-left: -10px;
  margin-top: -5px;
}

.slider-vertical {
  height: 210px;
  width: 20px;
}

.slider-vertical .slider-handle-container,
.slider-vertical .slider-track {
  width: 10px;
  margin-left: -5px;
  left: 50%;
}

.slider-vertical .slider-track {
  top: 0;
  bottom: 0;
}

.slider-vertical .slider-handle-container {
  top: 10px;
  bottom: 10px;
}
.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}

.slider-vertical .slider-handle {
  margin-left: -5px;
  margin-top: -10px;
}

.slider input,
.slider input[class*="span"] {
  display: none;
}

.slider .tooltip-inner {
  white-space: nowrap;
}

.slider-handle-container {
  position: absolute;
}

.slider-track {
  position: absolute;
  cursor: pointer;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.slider-selection {
  position: absolute;
  background-color: #e6e6e6;
  background-image: -moz-linear-gradient(top, #f0f0f0, #e0e0e0);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f0f0f0), to(#e0e0e0));
  background-image: -webkit-linear-gradient(top, #f0f0f0, #e0e0e0);
  background-image: -o-linear-gradient(top, #f0f0f0, #e0e0e0);
  background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
     -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
}

.slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slider-knob {
  width: 20px;
  height: 20px;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #e6e6e6, #ffffff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e6e6e6), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #e6e6e6, #ffffff);
  background-image: -o-linear-gradient(top, #e6e6e6, #ffffff);
  background-image: linear-gradient(to bottom, #e6e6e6, #ffffff);
  background-repeat: repeat-x;
  border: 1px solid #bbbbbb;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #a2a2a2;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
          border-radius: 20px;
}

.slider-handle:active .slider-knob,
.slider-handle:hover .slider-knob {
  background-color: #e6e6e6;
  background-position: 0 5px;
  -webkit-transition: background-position 0.1s linear;
     -moz-transition: background-position 0.1s linear;
       -o-transition: background-position 0.1s linear;
          transition: background-position 0.1s linear;
}